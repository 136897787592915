<template>
  <div id="publish">
    <div class="content">
      <!-- 左边 -->
      <div class="left" id="left">
        <div class="left-header">
          <h1
            style="
              font-family: '宋体，微软雅黑';
              font-weight: bold;
              font-size: 35px;
              color: #333333;
            "
          >
            待发布文件
          </h1>
        </div>
        <div class="left-custom-tree-container">
          <el-tree
            :data="list"
            node-key="id"
            accordion
            :props="defaultProps"
            :expand-on-click-node="false"
            :render-content="renderContent"
            :default-expanded-keys="addShowList"
          >
          </el-tree>
        </div>
      </div>
      <!-- @mousedown="mousedown" -->
      <div class="middle"></div>
      <!-- 右边 -->
      <div class="right" id="right">
        <div>
          <h1
            style="
              font-family: '宋体，微软雅黑';
              font-weight: bold;
              font-size: 35px;
              color: #333333;
            "
          >
            已发布文件
          </h1>
        </div>
        <div>
          <el-tree
            :data="PublishList"
            node-key="id"
            :props="defaultProps"
            accordion
            :expand-on-click-node="false"
            :render-content="renderContentRight"
            :default-expanded-keys="removeShowList"
          >
            <!-- <span slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span class="headerButton">
                <labBtn
                  :text="'发布'"
                  :classType="'text-Btn'"
                  @click="() => remove(node, data)"
                />
              </span>
            </span> -->
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let id = 1000;
export default {
  name: "DoubleCIndex",

  data() {
    return {
      isShowTooltip: false,
      file_args: [],
      list: [],
      PublishList: [],
      defaultProps: {
        children: "items",
        label: "label",
      },
      removeShowList: [],
      addShowList: [],
    };
  },
computed:{
  userInfo() {
      return this.$store.state.userInfo;
     },
    isDisableAuth()
    {
      return this.userInfo.username =='houwenzhao'
    },
},
  created() {
    this.getWaitingPublishData();
    this.getPublishData();
  },

  methods: {
    handleAuthBtn()
    {
      if (this.isDisableAuth) {
     this.$message.warning('该账号没有此权限');
    return false; 
  }
  return true; 
    },
    // 判断长度是否显示文字提醒
    visibilityChange(event) {
      // console.log(1);
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      // const content_weight = this.$refs.tlp.$el.parentNode.clientWidth; // 文本容器宽度(父节点)
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
        console.log(1);
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
        console.log(2);
      }
    },
    // // 树形结构拖拽完毕后触发
    // async handleNodeClickDrop(draggingNode, dropNode, dropType, event) {
    // function fn(PublishList) {
    //   for (let i = 0; i < PublishList.length; i++) {
    //     PublishList[i].number = i;
    //     if (PublishList[i].items) {
    //       fn(PublishList[i].items);
    //     }
    //   }
    // }
    //   fn(this.PublishList);
    //   this.file_args = this.PublishList;
    //   await this.api
    //     .postFormAPISM(
    //       { file_args: this.file_args },
    //       "/knowledge/sortkonwledge"
    //     )
    //     .then((res) => {
    //       console.log(res);
    //       // dropNode.parent.expanded=true;
    //     })
    //     .catch((error) => {
    //       console.log("getPublishData, error", error);
    //     });
    //   // this.getPublishData();

    // },
    // //  推拽后判断是否可以放置
    // allowDrop(draggingNode, dropNode, type) {
    //   if (type == "inner" && dropNode.label.includes("md")) {
    //     return false;
    //   }
    //   return true;
    // },
    // // 推拽前判断是否可以拖拽
    // allowDrag(draggingNode) {
    //   // return draggingNode.data.label.indexOf('三级 3-2-2') === -1;
    //   // console.log(draggingNode.level==1);
    //   return true;
    // },

    // 待发布区域渲染
    renderContent(h, { node, data, store }) {
      if (data.hasOwnProperty("items")) {
        return (
          <span class="custom-tree-node">
            <div class="leftFolder"></div>
            <span style="padding-left: 10px; margin: 0 auto;">
              {node.label}
            </span>
          </span>
        );
      } else {
        if (data.release_status) {
          return (
            <span
              class="custom-tree-node"
              v-on:mouseenter={() => this.visibilityChange}
            >
              <div class="leftTickets"></div>
              <span style="padding-left: 10px; margin: 0 auto;  ">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">{node.label}</div>
                  <labBtn
                    id="oneButton"
                    classType="text-Btn"
                    on-click={() => this.detailed(node, data)}
                  >
                    {node.label}
                  </labBtn>
                </el-tooltip>
              </span>
              <span style="padding-left: 20px; margin: 0 auto; ">
                <el-button type="text" disabled style=" font-size: 12px;">
                  已发布
                </el-button>
              </span>
            </span>
          );
        } else {
          return (
            <span
              class="custom-tree-node"
              v-on:mouseenter={() => this.visibilityChange}
            >
              <div class="leftTickets"></div>
              <span style="padding-left: 10px; margin: 0 auto;  ">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">{node.label}</div>
                  <labBtn
                    id="oneButton"
                    classType="text-Btn"
                    on-click={() => this.detailed(node, data)}
                  >
                    {node.label}
                  </labBtn>
                </el-tooltip>
              </span>
              <span style="padding-left: 20px; margin: 0 auto; ">
                <labBtn
                  style=" font-size: 12px;"
                  text="发布"
                  classType="text-Btn"
                  on-click={() => this.add(node, data)}
                />
              </span>
            </span>
          );
        }
      }
    },
    //  已发布区域渲染
    renderContentRight(h, { node, data, store }) {
      if (data.hasOwnProperty("items")) {
        return (
          <span class="custom-tree-node">
            <div class="rightFolder"></div>
            <span style="padding-left: 10px; margin: 0 auto;">
              <span style="font-size: 14px; width: 448px; display: inline-table;">
                {node.label}
              </span>
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            <div class="rightTickets"></div>
            <span style="padding-left: 10px; margin: 0 auto; width: 440px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: left;display: inline-block;vertical-align:bottom">
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">{node.label}</div>
                <span style="font-size: 14px;">{node.label} </span>
              </el-tooltip>
            </span>
            <span>
              <labBtn
                style=" font-size: 12px;"
                text="删除"
                classType="text-Btn"
                on-click={() => this.remove(node, data)}
              />
            </span>
            <span style="margin-left: 5px; ">
              <labBtn
                style=" font-size: 12px;"
                text="上移"
                classType="text-Btn"
                on-click={() => this.moveUpItem(node, data)}
              />
              <i class="el-icon-top"></i>
            </span>
            <span style="margin-left: 5px; ">
              <labBtn
                style=" font-size: 12px;"
                text="下移"
                classType="text-Btn"
                on-click={() => this.moveDownItem(node, data)}
              />
              <i class="el-icon-bottom"></i>
            </span>
          </span>
        );
      }
    },
    // 位置下移动
    async moveDownItem(node, data) {
      let uplist = "";
      this.PublishList.forEach((item) => {
        if (item.label == node.parent.label) {
          console.log(item, "item");
          uplist = item;
        }
      });
      this.removeShowList = [];
      this.removeShowList.push(node.parent.id, node.parent.key);
      let index = uplist.items.findIndex((item) => {
        return item.label == data.label;
      });
      // console.log(index);
      // console.log(data);

      if (index == uplist.items.length - 1) {
        return this.$message({
          message: "已经是最后一个了",
          type: "warning",
        });
      }

      uplist.items.splice(index + 2, 0, data);
      uplist.items.splice(index, 1);
      console.log(uplist.items);
      function fn(PublishList) {
        for (let i = 0; i < PublishList.length; i++) {
          PublishList[i].number = i;
          if (PublishList[i].items) {
            fn(PublishList[i].items);
          }
        }
      }
      fn(this.PublishList);
      this.file_args = this.PublishList;
      await this.api
        .postFormAPISM(
          { file_args: this.file_args },
          "/knowledge/sortkonwledge"
        )
        .then((res) => {
          console.log(res);
          // dropNode.parent.expanded=true;
        })
        .catch((error) => {
          console.log("getPublishData, error", error);
        });
      this.getPublishData();
    },
    // 位置上移动
    async moveUpItem(node, data) {
      // console.log(node);
      let uplist = "";
      this.PublishList.forEach((item) => {
        if (item.label == node.parent.label) {
          console.log(item, "item");
          uplist = item;
        }
      });
      this.removeShowList = [];
      this.removeShowList.push(node.parent.id, node.parent.key);
      let index = uplist.items.findIndex((item) => {
        return item.label == data.label;
      });
      // console.log(index);
      // console.log(data);
      if (index == 0) {
        this.$message({
          message: "已经是第一个了",
          type: "warning",
        });
        return;
      }

      uplist.items.splice(index - 1, 0, data);
      uplist.items.splice(index + 1, 1);
      // console.log(uplist.items);
      function fn(PublishList) {
        for (let i = 0; i < PublishList.length; i++) {
          PublishList[i].number = i;
          if (PublishList[i].items) {
            fn(PublishList[i].items);
          }
        }
      }
      fn(this.PublishList);
      this.file_args = this.PublishList;
      await this.api
        .postFormAPISM(
          { file_args: this.file_args },
          "/knowledge/sortkonwledge"
        )
        .then((res) => {
          console.log(res);
          // dropNode.parent.expanded=true;
        })
        .catch((error) => {
          console.log("getPublishData, error", error);
        });
      this.getPublishData();
    },
    // 点击获取待发布文档的详细信息
    detailed(node, data) {
      window.open(
        `http://47.104.68.37:13010/get_started/calc/${
          data.file.split(".")[0]
        }.html`,
        "_blank"
      );
    },

    //  拿到待发布区域的数据
    getWaitingPublishData() {
      this.api
        .postFormAPISM({}, "/knowledge/getadminknowledge")
        .then((res) => {
          let id = 1;
          function fn(data) {
            for (const value of data) {
              (value.label = value.label.replace(/.md$/, "")),
                (value.id = id++);
              if (value.items) {
                fn(value.items);
              }
            }
            return data;
          }
          this.list = fn(res.data.data);
          //   console.log(this.list, "list");
        })
        .catch((error) => {
          console.log("getPublishData, error", error);
        });
    },
    //  拿到已发布区域的数据
    getPublishData() {
      this.api
        .postFormAPISM({}, "/knowledge/getknowledge")
        .then((res) => {
          // console.log(res.data.data);
          let id = 1;
          function fn(data) {
            for (const value of data) {
              (value.label = value.label.replace(/.md$/, "")),
                (value.id = id++);
              if (value.items) {
                fn(value.items);
              }
            }
            return data;
          }
          this.PublishList = fn(res.data.data);
          // console.log(this.PublishList, "this.PublishList");
        })
        .catch((error) => {
          console.log("getPublishData, error", error);
        });
    },
    // 发布
    async add(node, data) {
      // console.log(data);
      if (!this.handleAuthBtn()) return; 
      const name = data.label;
      // console.log(name);
      const url = data.file.split("\\");

      if (url.length == 1) {
        // 如果数据是在第一层
        let url1 = `http://47.104.68.37:13010/get_started/calc/${url}`;
        // console.log(url1);
        if (url1.includes("md")) {
          // 如果数据包含md
          let url2 = url1.split(".");
          let url3 = url2.pop();
          let url4 = url2.push("html");
          let url5 = url2.join(".");
          let url6 = `http://47.104.68.37:13010/get_started/calc/${url5}`;
          // console.log(url5);
          await this.api
            .postFormAPISM(
              { file_name: name, file_url: url6, file_dir: "" },
              "/knowledge/release"
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log("添加错误", error);
            });
          // this.getWaitingPublishData();
          this.getWaitingPublishData();
          this.getPublishData();
          this.addShowList = [];
          this.addShowList.push(node.parent.id, node.parent.key);
          return;
        }
        await this.api
          .postFormAPISM(
            { file_name: name, file_url: url1, file_dir: "" },
            "/knowledge/release"
          )
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log("添加错误", error);
          });
      } else {
        let dir = url;
        let dir1 = dir.pop();
        let dir2 = dir.join("\\");
        let dir3 = `\\${dir2}`;
        console.log(dir3, "dir");
        if (data.label.includes("md")) {
          // 如果数据包含md
          let url1 = data.file.split("\\");
          let url2 = url1.pop();
          let url3 = data.label;
          let url4 = url3.split(".");
          let url5 = url4.pop();
          let url6 = url4.push("html");
          let url7 = url4.join(".");
          let url8 = url1.push(url7);
          let url9 = url1.join("/");
          let url10 = `http://47.104.68.37:13010/get_started/calc/${url9}`;
          console.log(url10, "url");
          await this.api
            .postFormAPISM(
              { file_name: name, file_url: url10, file_dir: dir3 },
              "/knowledge/release"
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log("error", error);
            });
          // this.getWaitingPublishData();
          this.getWaitingPublishData();
          this.getPublishData();
          this.addShowList = [];
          this.addShowList.push(node.parent.id, node.parent.key);
          return;
        }
        let url1 = url.join("/");
        let url2 = `http://47.104.68.37:13010/get_started/calc/${url1}/${name}`;
        console.log(url2, "url");
        // 如果如数是在第二层
        await this.api
          .postFormAPISM(
            { file_name: name, file_url: url2, file_dir: dir3 },
            "/knowledge/release"
          )
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log("getPublishData, error", error);
          });
      }
      this.getWaitingPublishData();
      this.getPublishData();
      this.addShowList = [];
      this.addShowList.push(node.parent.id, node.parent.key);
    },
    // 删除
    async remove(node, data) {
      if (!this.handleAuthBtn()) return; 
      console.log(node);
      // console.log(data);
      if (!data.hasOwnProperty("items")) {
        const name = data.label;
        let dir = data.file.split("/");
        let dir1 = dir.splice(0, 5);
        let dir2 = dir.pop();
        console.log(dir);
        if (dir.length) {
          // console.log(1);
          let dir3 = dir.join("\\");
          let dir4 = `\\${dir3}`;
          // 删除文件 不在第一层
          await this.api
            .postFormAPISM(
              { file_name: name, file_dir: dir4 },
              "/knowledge/deleteknowledge"
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log("getPublishData, error", error);
            });
          this.getWaitingPublishData();
          this.getPublishData();
          this.removeShowList = [];
          this.removeShowList.push(node.parent.id, node.parent.key);
        } else {
          // console.log(2);
          // console.log(dir);
          // 删除文件 在第一层
          await this.api
            .postFormAPISM(
              { file_name: name, file_dir: "" },
              "/knowledge/deleteknowledge"
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log("删除错误", error);
            });
          this.getWaitingPublishData();
          this.getPublishData();
          this.removeShowList = [];
          this.removeShowList.push(node.parent.id, node.parent.key);
        }
      }
    },
    // 2个div滑动效果
    mousedown(e) {
      console.log(e);
      // 父级框的宽度
      let divWidth = document.getElementById("publish").clientWidth;
      //添加鼠标移动事件
      document.onmousemove = function(e) {
        // 防止链接打开 URL
        e.preventDefault();
        // e.clientX是鼠标指针相对于浏览器页面（或客户区）的水平坐标
        // 可以理解为鼠标距离屏幕左侧的距离
        // 当鼠标距离左面大于300和鼠标位置小于父级总长度-220
        //就是控制左面和右边两个的最小宽度
        if (e.clientX > 300 && e.clientX < divWidth - 220) {
          //设置左面的大小
          document.getElementById("left").style.width = e.clientX + "px";
          //设置右边的大小
          document.getElementById("right").style.width =
            divWidth - e.clientX - 10 + "px";
        }
      };
      document.onmouseup = function(e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
  },
};
</script>

<style lang="less">
#publish {
  width: 1300px;
  height: 700px;
  margin: 0 auto;
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      width: 49%;
      height: 100%;
      // border-right: 1px solid;
      & .left-custom-tree-container {
        & .el-tree {
          & .el-tree-node {
            & .el-tree-node__content {
              & .custom-tree-node {
                display: flex;
                & .leftFolder {
                  background-image: url("../../assets/images/folder.svg");
                  height: 16px;
                  width: 16px;
                  background-size: contain;
                }
              }
            }
            & .el-tree-node__children {
              & .el-tree-node {
                & .el-tree-node__content {
                  & .custom-tree-node {
                    display: flex;
                    & .leftTickets {
                      background-image: url("../../assets/images/tickets.svg");
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
          & .el-tree-node__content {
            & #oneButton {
              width: 450px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              display: inline-table;
            }
          }
        }
      }
    }
    .middle {
      height: 100%;
      width: 1px;
      background-color: #d0d0d0;
      cursor: w-resize;
      // background-image: url(../../assets/images/tickets.svg);
      // background-size: cover;
    }
    .right {
      width: 49%;
      height: 100%;
      & .el-tree {
        & .el-tree-node {
          & .el-tree-node__content {
            & .custom-tree-node {
              display: flex;
              & .rightFolder {
                background-image: url("../../assets/images/folder.svg");
                width: 16px;
                height: 16px;
                background-size: contain;
              }
            }
          }
          & .el-tree-node__children {
            & .el-tree-node {
              & .el-tree-node__content {
                & .custom-tree-node {
                  display: flex;
                  & .rightTickets {
                    // background-color: red;
                    background-image: url("../../assets/images/tickets.svg");
                    width: 16px;
                    height: 16px;
                    background-size: contain;
                  }
                }
              }
            }
          }
        }
      }
    }
    .headerButton {
      padding-left: 20px;
    }
  }
}
</style>
